@import "../../lib/scss/variables.scss";

.header-title {
  text-align: left;
  font-size: 26px;
  font-weight: 300;
  padding-bottom: 0;
}

.table-card {
  .ant-card-head {
    margin-bottom: 0; 
    border-bottom: 1px solid #e8e8e8;
  }
  .ant-card-body {
    padding: 0px
  }
  .ant-table-pagination.ant-pagination {
    margin-right: 24px;
  }

  // Card Actions
  .ant-card-actions {
    border-top: none;
  }

  // Table Head
  .ant-table-thead > tr > th { 
    background-color: #fff;
  }

  .clickable {
    cursor: pointer;
  }

  // Table Body
  .ant-table-tbody {
    .actions {
      opacity: 0;
    }
    tr {
      background-color: #fcfcfc00;
    }
  }
  .ant-table-row.active {
    background-color: #fcfcfc !important;
  }
  .ant-table-row:hover, .ant-table-row.actions-visible {
    .actions {
      opacity: 1;
    }
    tr > td {
      background-color: #fcfcfc;
    }
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: hsla(0, 0%, 99%, 1);

  }
}
