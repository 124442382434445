@import '../../lib/scss/variables.scss';

.navbar {
  .logo {
    width: 150px;
    height: 37px;
    background: rgba(255, 255, 255, 0.2);
    margin: 13px 54px 13px 0;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 18px;
      margin-top: -2px;
      color: #fff;
    }
  }
  .menu {
    line-height: 64px;
  }
  .account {
    float: right;
  }
}


@include media-breakpoint-down("md") {
  .navbar {
    padding: 0 25px;
    .logo {
      width: 150px;
      height: 37px;
      background: rgba(255, 255, 255, 0.2);
      margin: 13px 14px 13px 0;
    }
  }
}
