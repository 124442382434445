.title {
  text-align: left;
  font-weight: 300;
  padding-bottom: 0;
}

.title.lg {
  font-size: 30px;
}

.title.md {
  font-size: 26px;
}

.title.sm {
  font-size: 22px;
}

.title.xs {
  font-size: 18px;
}
