@import "./variables.scss";

.app-layout {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  .menu-content-wrapper {
    flex-direction: row;
  }
}

.app-layout-content {
  height: 100%;
  width: -webkit-fill-available;
  padding: 20px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

@include media-breakpoint-down("md") {
  .app-layout-content {
    padding: 0px;
  }
}

.card-title {
  display: flex;
  align-items: center;
}

.drawer-medium {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 500px;
  }
}

.drawer-small {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 300px;
  }
}

* {
  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: hsla(0, 0%, 88%, 1);
  }

  ::-webkit-scrollbar-thumb {
    background-color: hsla(209, 100%, 8%, 0.3);
  }
}

//////////////////
//// Overides ////
////////////////// 

// Buttons
.ant-btn-circle.ant-btn-sm {
  border: none;
  box-shadow: none;
}

.ant-btn-primary.ant-btn-circle.ant-btn-sm {
  color: hsla(213, 100%, 52%, 1);
  background-color: #f5f5f500;
}

.ant-btn-primary.ant-btn-circle.ant-btn-sm:hover {
  color: #ffffff;
  background-color: #1890ff;
}

.ant-btn-danger.ant-btn-circle.ant-btn-sm {
  color: #f5222d;
  background-color: #f5f5f500;
}

.ant-btn-danger.ant-btn-circle.ant-btn-sm:hover {
  color: #fff;
  background-color: #f5222d;
}

.btn-no-outline {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
}

// Table

.ant-table table {
  border-collapse: collapse; // default border rows
}

.ant-table-row {
  border-bottom: 1px solid #e8e8e8;
}

.ant-table-row:last-child {
  border-bottom: none;
}

.row-selectable {
  cursor: pointer;
}

.ant-table-small>.ant-table-content>.ant-table-body {
  margin: 0;
}

td {
  border: none !important;
}

// Tabs
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 17px;
}

///////////////////
//// Dark-Mode ////
///////////////////

$dm-duration: 0.9s;

#root-html {
  transition: filter $dm-duration;

  body {
    transition: filter $dm-duration;

    .ant-layout-header {
      transition: background-color $dm-duration;
    }

    .logo {
      transition: background-color $dm-duration;
    }

    .navbar>ul {
      transition: background-color $dm-duration;
    }
  }
}

#root-html.dark-mode {
  filter: invert(0.81);

  body {
    filter: hue-rotate(180deg);
    transition: filter 0.5s;

    .ant-layout-header {
      background-color: #ffffff;
    }

    .navbar {
      .logo {
        background: rgba(0, 0, 0, 0.3);
      }
    }

    .navbar>ul {
      background-color: #fff;

      .ant-menu-item>a {
        color: rgba(0, 0, 0, 0.65)
      }

      .ant-menu-item>a:hover {
        color: rgba(0, 0, 0, 1)
      }

      .ant-menu-item.ant-menu-item-selected>a {
        color: rgba(0, 0, 0, 1)
      }

    }

    .app-layout {
      filter: grayscale(0.3);
    }
  }
} 