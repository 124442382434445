.report-table-pane {
  .pane-body {
    padding: 0 !important;
  }
  .ant-tabs-tabpane {
    padding: 0 20px;
  }
}

.report-result-wrapper {
  overflow-x: auto;
}