.field-item {
  background-color: #fff;
  padding: 12px;
  margin: 5px 0px;
  .ant-card-body {
    display: flex;
    flex-direction: row;
    .actions {
      opacity: 0;
      margin-left: auto;
    }
  }
}

.field-item.disabled {
  border: 1px dashed #1890ff;
  background-color: #1890ff14;
}

.field-item-wrapper :hover {
  .actions {
    opacity: 1;
  }
}

.drop-zone > div {
  width: 80%;
}