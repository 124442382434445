.table-pane>.ant-card-head {
  background-color: hsl(0, 0%, 98%);
  border-bottom: none;
  padding-left: 16px;
  .ant-card-head-title {
    padding-bottom: 0;
  }
}

.table-pane>.ant-card-body {
  padding: 0;

  .table {
    padding-top: 1px;
    .table-card {
      height: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      .ant-card-body {
        height: -webkit-fill-available;
      }
    }
    .ant-table-thead > tr > th {
      padding: 21px 16px
    }
  }

  .pane {
    .pane-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 16px;
      min-height: 65px;
      background-color: #fafafa;
      border-bottom: 1px solid #e8e8e8;
      .ant-table-column-title {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-align: left;
      }
      .actions {
        margin-left: auto
      }
    }
    .pane-body {
      overflow-y: auto;
      padding: 20px;
      border-left: 1px solid #e8e8e8;
    }
  }
}

.table-pane {
  .ant-card-actions > li:not(:last-child) {
    border-right: none
  }
}