.list-card {
  .ant-card-body {
    padding: 1px 0 0 0;
  }
  .ant-list-item {
    padding: 12px 12px;
    display: flex-root;
    .actions {
      margin-left: auto;
    }
  }
  .clickable {
    cursor: pointer;
  }
  .ant-list-items > li {
    transition: background-color ease 0.2s;
    .actions {
      display: flex;
      opacity: 0;
      transition: opacity ease 0.2s;
    }
  }
  .ant-list-items > li:hover, .ant-list-items > .active {
    background-color: hsla(0, 0%, 99%, 1);
    .actions {
      opacity: 1;
      transition: opacity ease 0.4s;
    }
  }
}