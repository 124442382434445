@import "../../lib/scss/breakpoints.scss";

.login {
  @include centerer;
  h2 {
    margin-bottom: 20px;
  }
  box-shadow: 0px 2px 10px -2px #d4d4d4;
  width: 500px;
}

.signup {
  @include centerer;
  h2 {
    margin-bottom: 20px;
  }
  box-shadow: 0px 2px 10px -2px #d4d4d4;
  width: 500px;
}

.form-error {
  margin-bottom: 24px;
  color: crimson;
}

.form-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}