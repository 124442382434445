@import "../../lib/scss/variables.scss";

.field-wrapper {
  display: flex;
  align-items: center;
  .name {
    // margin-right: 10px;
    display: flex;
    // width: 20%;
    line-height: 1.6;
    .required {
      margin-left: 2px;
      color: crimson;
    }
  }
  .select-field {
    width: 100%;
  }
}

.field-wrapper.richtext {
  flex-direction: column;
  align-items: initial;
  .name {
    width: auto;
    margin-bottom: 6px;
  }
  .field {
    width: auto;
  }
}

.icon-no-spin {
  svg {
    transform: rotate(0deg) !important;
  }
}

// Data Display

.data-display > .ant-descriptions {
  margin-bottom: 20px;
}

.data-item-null {
  width: 50%;
  margin-left: 25%;
  height: 1px;
  border-top: 1px dashed #e8e8e8;
}

// Mobile

@include media-breakpoint-down("sm") {
  .field-column {
    padding-left: 0px !important;
  }
  .field-wrapper {
    display: flex;
    flex-wrap: wrap;
    .name {
      // margin-right: 10px;
      display: flex;
      // width: 20%;
      line-height: 1.6;
      .required {
        margin-left: 2px;
        color: crimson;
      }
    }
    .select-field {
      width: 100%;
    }
  }

  // display
  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    font-size: 11px;
  }
  .ant-descriptions.bordered.small .ant-descriptions-item-label {
    padding: 7px;
  }
  .ant-descriptions-view table {
    table-layout: fixed;
  }

  .ant-descriptions-row {
    display: flex;
    flex-direction: column;
  }
}