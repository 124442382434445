.favicon-img {
  width: 25px;
  height: 25px;
}

#root-html.dark-mode {
  .favicon-img-wrapper > span {
    filter: saturate(3);
  }

  .favicon-img-wrapper {
    filter: invert(1);

    .favicon-img {
      filter: hue-rotate(190deg);
    }
  }
}