.field-group {
  min-width: 300px;
  min-height: 100px;
  margin: 10px 0px;
  .ant-card-head {
    background-color: #fafafa;
    border-bottom: none;
    padding-left: 16px;
  }
  .ant-card-head-title {
    display: flex;
  }
  .ant-card-body {
    padding: 0;
  }
  .drop-zone {
    margin: 10px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.field-group.no-group {
  .title {
    margin: 16px 0 0 16px;
  }
}

