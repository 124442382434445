@import "../../lib/scss/variables.scss";
$accent-color: #fafafa;
$border-radius: 4px;

.data-pane {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: $border-radius;
  width: 100%;

  .header {
    padding: 10px 20px;
    background-color: $accent-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .title {
      text-align: left;
      font-size: 26px;
      font-weight: 300;
      padding-bottom: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 0;
    overflow: hidden;
    height: inherit;

    .table {
      flex: 0 0 50%;
      width: 50%;
      overflow: none;

      .ant-table-body {
        direction: rtl;
        background-color: hsla(0, 0%, 99%, 1);

        table {
          direction: ltr;
          border-collapse: separate;

          .row-active {
            td {
              background-color: #e6f7ff;
              transition: background-color ease 0.5s 
            }
          }

          // force add row border
          td {
            border-bottom: 1px solid #e8e8e8 !important;
          }

          // sticky header row
          .ant-table-thead>tr>th {
            position: sticky;
            top: 0;
            z-index: 1;
          }
        }
      }

      .ant-table-body::-webkit-scrollbar-track {
        margin-top: 53px;
      }

      .ant-pagination {
        display: none;
      }
    }

    .pane-wrapper {
      flex: 0 0 50%;
      width: 50%;
      overflow: none;

      .header {
        display: flex;
        align-items: center;
        height: 54px;
        border-bottom: 1px solid #e8e8e8;
      }

      .pane {
        border-left: 1px solid #e8e8e8;
        overflow-y: auto;
        padding: 20px;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    padding: 10px 20px;
    background-color: $accent-color;
    border-top: 1px solid #e8e8e8;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .pager {
      flex: 0 0 50%;

      .ant-pagination {
        display: flex;
        justify-content: center;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
    }
  }
}

@include media-breakpoint-down("md") {
  .data-pane {
    height: max-content;
    overflow-y: visible;

    .ant-table-thead>tr>th {
      padding: 10px 12px;
      font-size: 11px;
    }

    .ant-table-tbody>tr>td {
      padding: 5px 5px;
      font-size: 11px;
    }

    .content {
      display: flex;
      flex-direction: row;
      flex-flow: column;
      overflow-y: visible;
      height: auto;

      .ant-table-body {
        max-height: 300px !important;
      }

      .table {
        width: 100% !important;

        .ant-table-body::-webkit-scrollbar-track {
          margin-top: 36px;
        }
      }

      .pane-wrapper {
        height: auto !important;
        overflow-y: visible;
        width: 100% !important;

        .pane {
          height: auto !important;
          overflow-y: visible;
        }
      }
    }

    .footer {
      flex-flow: column;
      align-items: center;

      .pager {
        .ant-pagination {
          display: flex;
          justify-content: center;
        }
      }

      .actions {
        margin-top: 10px;
      }
    }
  }
}

// Table - reposition search icon
$left-offset: 185px;
$nice-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

.data-pane {
  .ant-table-filter-icon {
    position: relative !important;
    vertical-align: super !important;
  }

  .ant-table-filter-dropdown>div {
    background: #fff;
    box-shadow: $nice-box-shadow;
    -webkit-box-shadow: $nice-box-shadow;
    border-radius: 4px;
  }

  .ant-table-filter-dropdown {
    left: $left-offset;
    margin-top: 20px;
    background: none;
    box-shadow: none;
    -webkit-box-shadow: none;

    .custom-filter-menu {
      left: -$left-offset;
      position: relative;
    }
  }
}