.side-menu {
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  direction: rtl;
  margin-bottom: 48px;
  .ant-layout-sider-children {
    direction: ltr;
    .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
      border-right: 4px solid #1890ff;
    }
  }
}

.ant-layout-sider-collapsed {
  .side-menu-option-title {
    display: none;
  }
}