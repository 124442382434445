.report-row {
  padding: 10px 5px;
  border-bottom: 1px solid #e8e8e8;
  .name {
    cursor: pointer;
  }
}

.report-row.active {
  .name {
    font-weight: 700;
  }
}

.report-list-add-btn {
  margin: 10px 0;
  margin-left: -2px;
}